import React from 'react';
import { Paper, Box, ListSubheader, List, ListItemButton, ListItemText } from '@mui/material';
import { useNavigate } from "react-router-dom";

const ParamSelect = () => {
  const navigate = useNavigate();
  return (
    <Box display="flex" justifyContent="center">
      <Paper component="main" maxWidth="xs" sx={{ width: '100%', maxWidth: 360 }}>
        <List
          sx={{ width: '100%', maxWidth: 360 }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              計算パラメーター選択
            </ListSubheader>
          }
        >
          <ListItemButton>
            <ListItemText primary="前回入力した値" />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/calculator")}>
            <ListItemText primary="デフォルト値" />
          </ListItemButton>
        </List>
      </Paper>
    </Box>
  );
}

export default ParamSelect