import React, { useRef } from 'react'
import { styled } from '@mui/material/styles';
import { TextField, Grid, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import MilkingCurvePlot from './MilkingCurvePlot';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 120,
  },
});


const calcMilkingYieldKg = (dim, scale, ramp, decay) => scale*(1-(Math.exp((-dim)/ramp)/2))*Math.exp(-decay*dim)

const MikingCurveCalc = (props) => {
    const DEFAULT_PARAM_IDX = props.presetIdx;
    const FREE_EDIT_PARAM_IDX = 100;
    const scaleRef = useRef(null)
    const rampRef = useRef(null)
    const decayRef = useRef(null)
    const [paramIdx, setParamIdx] = React.useState(DEFAULT_PARAM_IDX);
    const milkingYieldArr = [...Array(props.params.calcDays)].map(
        (_, day) => calcMilkingYieldKg(day, props.params.scale, props.params.ramp, props.params.decay)
    )

    const updateParams = () => {
      setNewMilkParams(
        Number(scaleRef.current.value),
        Number(rampRef.current.value),
        Number(decayRef.current.value),
      )
    };

    const setNewMilkParams = (scale, ramp, decay) => {
      const totalMilkMount = [...Array(props.params.calcDays)].map(
        (_, day) => calcMilkingYieldKg(day, scale, ramp, decay)
      ).slice(0, 305).reduce((sum, element) => sum + element, 0);
      const newParams = {
          milkYield: parseInt(totalMilkMount),
          scale: scale,
          ramp: ramp,
          decay: decay,
          calcDays: props.params.calcDays
      }
      console.log(newParams)
      props.setParams(newParams)      
    }

    const changeParamType = (event) => {
      const newIdx = event.target.value
      setParamIdx(event.target.value);
      if(newIdx === FREE_EDIT_PARAM_IDX) return;
      const newParam = props.presets[newIdx];
      setNewMilkParams(newParam.scale, newParam.ramp, newParam.decay);
      scaleRef.current.value = newParam.scale;
      rampRef.current.value = newParam.ramp;
      decayRef.current.value = newParam.decay;
    }

    return (
      <Box m={2}>
        <MilkingCurvePlot dataArray={milkingYieldArr}/>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item sm={3} xs={12}>
          <FormControl variant="standard">
          <InputLabel id="demo-simple-select-standard-label">パラメータ種別</InputLabel>
            <Select
              value={paramIdx}
              label="乳量選択"
              onChange={changeParamType}
            >
              {
                props.presets.map((p, idx) => <MenuItem value={idx} key={p.label}>{p.label}</MenuItem>)
              }
              <MenuItem value={FREE_EDIT_PARAM_IDX}>自由入力</MenuItem>
            </Select>
            </FormControl>
          </Grid>
          <Grid item sm={3} xs={4}>
            <CustomWidthTooltip
              title="値を上げるとピーク乳量が高くなります。"
              placement="top" arrow enterTouchDelay={50} leaveTouchDelay={3000}
            >
              <TextField 
                label="Scale"
                name="scale"
                type="number"
                inputProps={{step: "0.1"}}
                inputRef={scaleRef}
                defaultValue={props.params.scale}
                onChange={updateParams}
                disabled={paramIdx !== FREE_EDIT_PARAM_IDX}
              />
            </CustomWidthTooltip>
          </Grid>
          <Grid item sm={3} xs={4}>
            <CustomWidthTooltip
              title="値を上げると泌乳ピークが早くなります。"
              placement="top" arrow enterTouchDelay={50} leaveTouchDelay={3000}
            >
              <TextField 
                label="Ramp"
                name="ramp"
                type="number"
                inputProps={{step: "0.01"}}
                inputRef={rampRef}
                defaultValue={props.params.ramp}
                onChange={updateParams}
                disabled={paramIdx !== FREE_EDIT_PARAM_IDX}
              />
            </CustomWidthTooltip>
          </Grid>
          <Grid item sm={3} xs={4}>
            <CustomWidthTooltip
              title="値を上げると泌乳持続性が下がります。"
              placement="top" arrow enterTouchDelay={50} leaveTouchDelay={3000}
            >
              <TextField 
                label="Decay"
                name="decay"
                type="number"
                inputProps={{step: "0.0001"}}
                inputRef={decayRef}
                defaultValue={props.params.decay}
                onChange={updateParams}
                disabled={paramIdx !== FREE_EDIT_PARAM_IDX}
              />
            </CustomWidthTooltip>
          </Grid>
        </Grid>
      </Box>
    )
}

export default MikingCurveCalc
