import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import {Box, Button, Container} from '@mui/material';
import { useNavigate, Link } from "react-router-dom";

const Select = () => {
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Container component="main" maxWidth="xs">
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Box fontSize={60} fontWeight={600}> 
            U-Prophet
          </Box>
          <Box fontSize={30} fontWeight={600} color="gray"> 
            ユープロフェット
          </Box>
          <Button
            fullWidth variant="contained" size="large" sx={{ mt: 3 }}
            onClick={() => loginWithRedirect()}
          >
            ログインして始める
          </Button>
          <Button
            fullWidth variant="contained" size="large" sx={{ mt: 1 }}
            onClick={() => loginWithRedirect({screen_hint: 'signup'})}
          >
            アカウントを作成して始める
          </Button>
          <Button
            fullWidth variant="contained" size="large" sx={{ mt: 3 }} color="secondary"
            onClick={() => navigate("temp-redirect")}
          >
            ログインせず始める
          </Button>
          <Box sx={{ fontSize: 12, marginTop: 1 }} style={{color:'#888888'}} display='inline'>
            ※ 計算前に簡単なアンケートにお答えください。
          </Box>
        </Box>
      </Container>
      <Box sx={{ fontSize: 15, marginTop: 5 }} style={{color:'#888888'}} display='inline'>
        <Link to="/policy">プライバシーポリシー</Link>をご確認の上、始めるボタンを押してください。
      </Box>
    </Box>
  );
}

export default Select