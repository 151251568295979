import React, {useEffect} from 'react';
import { CircularProgress, Container, Box } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import { getUserInfo } from "../../components/api/s3"


const Redirect = () => {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, user } = useAuth0();

  useEffect(() => {
    if(isLoading) return;
    if(!isAuthenticated) navigate("/");
    if(isAuthenticated) {
      const userid = user.sub;
      console.log(userid)
      getUserInfo(userid).then(
        (res) => {
          console.log(res);
          if(res.data.isSurveyExist){
            navigate("/calculator");
          }
          else{
            navigate("/signup-survey");
          }
      })
      .catch(err => {
        console.log(err)
        navigate("/signup-survey");
      });
    }
  }, [isAuthenticated, navigate, isLoading, user]);

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ fontSize: 24, color:'#666666', paddingRight: 2 }} display='inline'>登録情報読み込み中...</Box>
      <CircularProgress />
    </Container>
  )
}

export default Redirect