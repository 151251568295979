import React, {useState, useEffect} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import axios from 'axios';
import parameters from './parameters.json';

const CREE_CALC_API = "https://yawh23gbig.execute-api.ap-northeast-1.amazonaws.com/prod/calc"

const showNumOrSkelton = (num) => {
  return isNaN(num) ? <Skeleton variant="text" /> : num.toLocaleString()
}

export const parametersToPostJson = (farmType, inputParameters, whichCondition) => {
  const postJson = {"farm_type": farmType, "params": {}}
  inputParameters.forEach((r) => {
    postJson["params"][r.id] = r.value[whichCondition]
  })
  return postJson  
}

export const mergeRecivedJsonRes = (mergedResult, recivedParameter, whichCondition) => {
  Object.keys(recivedParameter).forEach((key) => {
    const rowIndex = mergedResult.findIndex((m) => m.id === key)
    if(rowIndex !== -1) {
      mergedResult[rowIndex].value[whichCondition] = parseInt(recivedParameter[key])
    }
  })
  return mergedResult
}

const CalcProcessing = () => {
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    let cleanedUp = false;
    const EXPECT_CALCULATION_TIME_SEC = 15.0;
    const UPDATE_CYCLE_SEC = 0.5;
    const ADDING_NUM_PER_UPDATE = 100.0 / EXPECT_CALCULATION_TIME_SEC * UPDATE_CYCLE_SEC;
    const timer = setInterval(() => {
      if(cleanedUp){
        clearInterval(timer);
        return;
      }
      setProgress(oldProgress => Math.min(oldProgress + ADDING_NUM_PER_UPDATE, 100));
    }, UPDATE_CYCLE_SEC*1000);
    return () => {
      cleanedUp = true;
      clearInterval(timer);
    };
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ fontSize: 24, color:'#666666', margin: 2 }}>シミュレーション実行中...</Box>
      <LinearProgress variant="determinate" value={progress} />
    </Container>
  )
}

const CalcError = (props) => {
  return (
    <Container component="main" maxWidth="xs">
      <Alert severity="error">
        <AlertTitle>計算失敗</AlertTitle>
        何らかの理由で計算に失敗しました。<br/>
        しばらくしてから再度実行してもエラーになる場合はデザミスまでお問い合わせください。<br/><br/>
        <strong>エラーメッセージ</strong><br/>
        {props.msg}
      </Alert>
    </Container>
  )
}

const ResultTable = (props) => {
  const result = props.result
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow style={{backgroundColor:'#c8e8f4'}}>
            <TableCell></TableCell>
            <TableCell align="right">現状</TableCell>
            <TableCell align="right">比較</TableCell>
            <TableCell align="right">差分</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow style={{backgroundColor:'#F0F0F0'}}>
              <TableCell component="th" scope="row">
                {result[0].label}
                <Box sx={{ fontSize: 12 }} style={{color:'#888888'}} display='inline'>{" [" + result[0].unit + "]"}</Box>
              </TableCell>
              <TableCell align="right">{showNumOrSkelton(result[0].value[0])}</TableCell>
              <TableCell align="right">{showNumOrSkelton(result[0].value[1])}</TableCell>
              <TableCell align="right">{showNumOrSkelton(result[0].value[1]-result[0].value[0])}</TableCell>
            </TableRow>            
          {result.slice(1, result.length).map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                <Box sx={{ padding: 2, fontSize: 13, color:'#888888' }} display='inline'>{row.label}</Box>
              </TableCell>
              <TableCell align="right">
                <Box sx={{fontSize: 12, color:'#888888'}}>{showNumOrSkelton(row.value[0])}</Box>
              </TableCell>
              <TableCell align="right">
                <Box sx={{fontSize: 12, color:'#888888'}}>{showNumOrSkelton(row.value[1])}</Box>
              </TableCell>
              <TableCell align="right">
                <Box sx={{fontSize: 12, color:'#888888'}}>{showNumOrSkelton(row.value[1] - row.value[0])}</Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );  
}

const STATE_CALCULATION_START = 0;
const STATE_CALCULATION_COMPLETE = 1;
const STATE_CALCULATION_FAIL = 2;

export default function Result(props) {
  const fileterResults = (results) => {
    const isContain = (str, val) => str.indexOf(val) !== -1
    if(props.farmType === "dairy") return results.filter((v) => isContain(v.farmType, "酪農"))
    else if(props.farmType === "breeding") return results.filter((v) => isContain(v.farmType, "繁殖"))
  }

  const [result, setResult] = useState(fileterResults(parameters.results.slice()));
  const [calcState, setCalcState] = useState(STATE_CALCULATION_START);
  const [errorMsg, setErrorMsg] = useState("");

  const resetResult = () => {
    const initRes = result.slice().map((elm) => {
      elm["value"] = ["-", "-"];
      return elm
    })
    setResult(initRes)
    setCalcState(STATE_CALCULATION_START)
  }

  const postParamToCreeCalcAPI = (whichCondition) => {
    const postJson = parametersToPostJson(props.farmType, props.inputs, whichCondition);
    console.log("post->", postJson)
    axios.post(CREE_CALC_API, postJson)
      .then((res) => {
        const data = JSON.parse(res["data"]["res"])
        const currentRes = result.slice()
        const mergedResult = mergeRecivedJsonRes(currentRes, data, whichCondition)
        setResult(mergedResult)
        if(calcState !== STATE_CALCULATION_FAIL) {
          setCalcState(STATE_CALCULATION_COMPLETE)
        }
      })
      .catch((error) => {
        setCalcState(STATE_CALCULATION_FAIL)
        setErrorMsg(String(error))
        console.log(error)
      });
  }

  useEffect(() => {
    resetResult();
    postParamToCreeCalcAPI(0);
    postParamToCreeCalcAPI(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={10} sx={{maxWidth: 500}}>
        {calcState === STATE_CALCULATION_START ? <CalcProcessing /> : null}
        {calcState === STATE_CALCULATION_COMPLETE ? <ResultTable result={result}/> : null}
        {calcState === STATE_CALCULATION_FAIL ? <CalcError msg={errorMsg}/> : null}
      </Grid>
    </Grid>
  );
}