import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {Box, Button, Typography, Card, CardContent, CardActions} from '@mui/material';

const useStyles = makeStyles({
  rightAlignItem: {
    marginLeft: "auto"
  }
});

const Consent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Box display="flex" justifyContent="center">
      <Card sx={{ maxWidth: 550 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            免責事項
          </Typography>
          <Typography variant="body1"><b>U-Prophet(ユープロフェット)</b> (以下、当サイトとする)では、牛の繁殖成績や各種相場情報などが農場の経済性に与える影響をシミュレーションすることが出来ます。</Typography>
          <Typography variant="body1">計算結果や機能については細心の注意を払って作成しておりますが、実際の経営内容を保証するものではなく、当サイト、またはコンテンツのご利用により、万一、ご利用者様に何らかの不都合や損害が発生したとしても、当社は何らの責任を負うものではありません。</Typography>
        </CardContent>
        <CardActions>
          <Button variant="contained" color="primary" className={classes.rightAlignItem}
            onClick={() => navigate("/calculator")}>
            同意する
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}

export default Consent
