import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {Box, Button, Typography, Card, CardContent, CardActions} from '@mui/material';

const useStyles = makeStyles({
  rightAlignItem: {
    marginLeft: "auto"
  }
});

const Policy = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Box display="flex" justifyContent="center">
      <Card sx={{ maxWidth: 800 }}>
        <CardContent>
          <Typography variant='h4'>
            プライバシー・ポリシー
          </Typography>
          この規定は、ユープロフェット(WEBアプリケーションのことをいい、以下、「本アプリ」といいます。)の利用者の個人情報，行動履歴及びそれらに準ずる情報(以下、「利用者情報」といいます。)の取扱いについて、デザミス株式会社の運用方針を定めたものです。

          <Typography variant="h5" sx={{ marginTop: 2 }}>第１条(方針)</Typography>
          弊社は個人情報の保護に関する法律その他関連法令等（以下、「個人情報保護法等」といいます。）及び本プライバシー・ポリシーに従い、適法かつ公正に利用者情報を収集・利用します。

            <Typography variant="h5" sx={{ marginTop: 2 }}>第２条(収集する情報)</Typography>
            弊社は以下の各号に掲げる利用者情報を収集・利用いたします。<br/>
            (1)　IPアドレス、端末機種情報、OSのバージョン<br/>
            (2)　パスワード、ログインID、トークンなどのログイン用認証情報<br/>
            (3)　利用者から提供を受けた情報(氏名、メールアドレスなど)<br/>
            (4)　本アプリ上の行動履歴情報(アクセスした機能、参照順など)<br/>

            <Typography variant="h5" sx={{ marginTop: 2 }}>第３条(利用目的)</Typography>
            弊社は収集した利用者情報を以下の各号に掲げる目的のために利用いたします。<br/>
            (1)　本アプリに関する登録、識別、利用実態分析など、本アプリの提供・維持・向上のため<br/>
            (2)　メンテナンス情報やアプリを便利に活用していただくための情報、その他重要なご連絡など、本アプリのサービスを運用する上で必要な皆様へのお知らせのため<br/>
            (3)　お問い合わせ対応のため<br/>
            (4)  本アプリへのログイン状態維持のため<br/>
            (5)　その他利用者の利便性向上のため<br/>

            <Typography variant="h5" sx={{ marginTop: 2 }}>第４条(第三者提供)</Typography>
            弊社は、利用者情報のうち、個人情報については、個人情報保護法等に基づき開示が認められる場合を除くほか、あらかじめ利用者の同意を得ずに第三者（日本国外にある者を含みます。）に提供することはありません。但し、次に掲げる場合はこの限りではありません。<br/>
            (1)　弊社が利用目的達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合<br/>
            (2)　合併その他の事由による事業の承継に伴って個人情報が提供される場合<br/>
            (3)　アクセス解析ツールや情報収集モジュール等の外部ツール提供者へ個人情報が提供される場合<br/>
            (4)　人の生命、身体又は財産の保護のために必要がある場合であって、利用者の同意を得ることが困難であると判断した場合<br/>
            (5)　公衆衛生の向上又は児童の健全な育成推進のために特に必要がある場合であって、利用者の同意を得ることが困難であると判断した場合<br/>
            (6)　国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用者の同意を得ることによって当該事務の遂行に支障を及ぼす恐れがある場合<br/>
            (7)　その他、個人情報保護法等で認められる場合<br/>

            <Typography variant="h5" sx={{ marginTop: 2 }}>第５条(Cookieの利用)</Typography>
            １．本アプリでは、第3条記載の目的のためにCookieを利用しています。<br/>
            ２．Cookieとは、利用者が本アプリにアクセスした時に、利用者のコンピュータまたはスマートフォンに配置される小さなテキストファイルのことです。なお、このファイルは、本アプリとサーバとの間で送受信した入力内容などを保存しておき、次回以降のログインを簡素化したり、本アプリをより効率的に作動させたりするために、利用されています。<br/>
            ３．利用者は、ブラウザの設定により、Cookieが送られてきたときに通知を受けたり、Cookieを無効にしたりすることができます。ただし、無効にしたことにより、本アプリが十分に機能しない場合があります。<br/>

            <Typography variant="h5" sx={{ marginTop: 2 }}>第６条(外部ツールの使用)</Typography>
            本アプリの提供にあたり、以下の外部ツール(サードパーティ・アプリケーション)を使用いたします。<br/>
            (1)　 Auth0<br/>
            <a href="https://auth0.com/jp">https://auth0.com/jp</a><br/>
            本アプリへのログイン処理を行うため、Auth0を使用しています。利用者のログインIDとパスワードが、暗号化された通信を用いてAuth0に送信されます。また、Auth0からトークン(ログイン情報を暗号変換した認証情報)を利用者端末が受信します。このトークンは、アプリ再起動時に利用者端末から弊社に送信され、再ログインを簡略化するものです。<br/>
            当該ツールのプライバシー・ポリシーや機能についての詳細は提供元の上記URLをご参照ください。<br/>
            (2)　Googleアナリティクス<br/>
            <a href="https://marketingplatform.google.com/intl/ja/about/analytics/">https://marketingplatform.google.com/intl/ja/about/analytics/</a><br/>
            本アプリでは、利便性向上を目的として利用状況を把握するために、アクセス解析ツールであるGoogleアナリティクスを使用しています。なお、この解析のためのデータは前条記載のCookieを利用して匿名で収集されており、個人を特定できるものではありません。<br/>
            当該ツールの利用規約及びプライバシー・ポリシーや機能についての詳細は提供元の上記URLをご参照ください。<br/>

            <Typography variant="h5" sx={{ marginTop: 2 }}>第７条(開示・訂正等の手続き)</Typography>
            １．弊社は、個人情報保護法等の定めに基づき利用者本人またはその代理人から個人情報の開示を求められたときは、本人またはその代理人による請求であることが確認できた上で、本人または代理人に対し、遅滞なくこれを開示します（当該個人情報がない場合にはその旨回答いたします。）。ただし、開示することにより次の各号に該当する場合は、その全部または一部を開示しないこともあります。<br/>
            (1)　本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合<br/>
            (2)　幣社の業務の適正な実施に著しい支障を及ぼすおそれがある場合<br/>
            (3)　その他法令に違反することになる場合<br/>
            ２．利用者またはその代理人は、個人情報の内容が事実でない場合には、弊社が定める手続きにより、個人情報の訂正、追加または削除（以下、「訂正等」という）を請求することができます。<br/>
            　　請求に係る手続については、お手数ですが第11条記載のお問い合わせ窓口までご連絡をお願いします。<br/>
            ３．弊社は、利用者またはその代理人から前項の請求を受けて、個人情報保護法等に基づきその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正等を行うものとします。<br/>
            ４．弊社は、第1項の開示をしない決定をしたとき、訂正等を行ったとき、または訂正等を行わない旨の決定をしたときは、遅滞なく、これを当該請求者に通知します。<br/>

            <Typography variant="h5" sx={{ marginTop: 2 }}>第８条(利用停止手続き)</Typography>
            利用者またはその代理人は、弊社に対して自己の利用者情報の収集・利用の停止を要請することができます。収集・利用停止の要請があった場合、弊社は、利用者またはその代理人による要請であることが確認できた上で、対応可能な範囲で速やかに利用者情報の収集を停止または消去いたします。ただし、この停止により本アプリのサービスを満足に受けられなくなったとしても弊社は一切の責を負わないものとし、利用者もそれを承諾したものとします。

            <Typography variant="h5" sx={{ marginTop: 2 }}>第９条(安全管理)</Typography>
            弊社は、利用者情報について、不正アクセス、漏洩、滅失又は毀損（紛失、破壊、改竄等を含む）を防止するため、厳正な管理の下で安全管理措置を講じるとともに継続的に見直しを行い、是正、改善に努めます。

            <Typography variant="h5" sx={{ marginTop: 2 }}>第１０条(プライバシー・ポリシーの変更)</Typography>
            弊社は、個人情報保護法等の定めがある場合を除き、本プライバシー・ポリシーを適宜改定できるものとします。

            <Typography variant="h5" sx={{ marginTop: 2 }}>第１１条(お問い合わせ窓口)</Typography>
            個人情報を含む利用者情報、本プライバシー・ポリシーに関するご意見、ご質問、苦情のお申し出その他お問い合わせは下記までお願い致します。<br/><br/>

            デザミス株式会社<br/>
            ump_support@desamis.co.jp<br/><br/>

            附則<br/>
            2022年3月1日制定<br/>

            デザミス株式会社<br/>

        </CardContent>
        <CardActions>
          <Button variant="contained" color="primary" className={classes.rightAlignItem}
            onClick={() => navigate("/")}>
            確認して戻る
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}

export default Policy
