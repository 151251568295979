import axios from "axios"

const S3_API_URL = "https://urcg5co8n3.execute-api.ap-northeast-1.amazonaws.com/prod/"

const postSurvey = (userid, resultJson) => {
    const url = S3_API_URL + "survey/" + userid + "/";
    return axios.put(encodeURI(url), JSON.stringify(resultJson, null , "\t"));
}

const postCalcParams = (userid, calcparamsJson) => {
    const timestamp = String(Date.now());
    const url = S3_API_URL + "calc_params/" + userid + "/" + timestamp + "/";
    return axios.put(encodeURI(url), JSON.stringify(calcparamsJson, null , "\t"));
}

const getUserInfo = (userid) => {
    const url = S3_API_URL + "user_info/" + userid + "/";
    return axios.get(encodeURI(url));
}

const postUserInfo = (userid, presetParameters=[]) => {
    const url = S3_API_URL + "user_info/" + userid + "/";
    const postJson = {"isSurveyExist": true, "presetParameters": presetParameters};
    return axios.put(encodeURI(url), JSON.stringify(postJson, null , "\t"));
}

export {postSurvey, postCalcParams, getUserInfo, postUserInfo};