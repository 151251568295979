import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Tab, Tabs, IconButton} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Description from '@mui/icons-material/Description';
import HelpOutline from '@mui/icons-material/HelpOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import MilkingCurveCalc from './MilkingCurveCalc';
import Dialog from '@mui/material/Dialog';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 150,
  },
});

const StepMoveButton = (props) => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={2}>
        <Button variant="outlined" color="primary" xs={1}
          onClick={() => props.moveToPrevStep()}>
          戻る
        </Button>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={6} textAlign="right">
        <Button variant="contained" color="primary"  xs={1}
          onClick={() => props.moveToShowResultStep()}
          disabled={props.disableButton}>
          結果を表示
        </Button>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles({
  bold: {
    fontWeight: 520,
    color: "#606060"
  }
})


function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const MikingInput = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [milkConfIdx, setMilkConfIdx] = React.useState(0);
  const handleOpen = (idx) => {setOpen(true); setMilkConfIdx(idx)}
  const handleClose = () => setOpen(false);

  const handleChange = (event) => {
    const newInput = JSON.parse(JSON.stringify(props.milkingParams.milkYield))
    if (event.target.name === "currntVal") newInput.value[0] = Number(event.target.value)
    else if (event.target.name === "compareVal") newInput.value[1] = Number(event.target.value)
    else return
    props.setMilkYield(newInput)
  };

  const isInvalidValue = (valstr) => {
    const valnumber = Number(valstr)
    if (isNaN(valnumber)) return true
    return valnumber < props.milkingParams.milkYield.min || valnumber > props.milkingParams.milkYield.max
  }

  const invalidMessage = String(props.milkingParams.milkYield.min) + "～" + String(props.milkingParams.milkYield.max) + "の数値を入力してください。"

  const setMilkingParams = (params) => {
    const newMilkYield = JSON.parse(JSON.stringify(props.milkingParams.milkYield));
    newMilkYield.value[milkConfIdx] = params.milkYield;
    const newScale = JSON.parse(JSON.stringify(props.milkingParams.scale));
    newScale.value[milkConfIdx] = params.scale;
    const newRamp = JSON.parse(JSON.stringify(props.milkingParams.ramp));
    newRamp.value[milkConfIdx] = params.ramp;
    const newDecay = JSON.parse(JSON.stringify(props.milkingParams.decay));
    newDecay.value[milkConfIdx] = params.decay;
    props.setMilkingParams({
      milkYield: newMilkYield,
      scale: newScale,
      ramp: newRamp,
      decay: newDecay,
    })
  }

  const milkingParams = (idx) => {
    return {
      scale: props.milkingParams.scale.value[idx],
      ramp: props.milkingParams.ramp.value[idx],
      decay: props.milkingParams.decay.value[idx],
      calcDays: 500
    }
  }

  return (
    <Grid container alignItems="center" justifyContent="center"
          columnSpacing={{ xs: 2, sm: 2 }}>
      <Grid item xs={12} sm={6}>
        <CustomWidthTooltip
          title={"hint" in props.milkingParams.milkYield ? props.milkingParams.milkYield.hint : ""}
          placement="top" arrow enterTouchDelay={50} leaveTouchDelay={3000}
        >
          <Typography sx={{ mt: 2, mb: 1 , ml: 5}} className={classes.bold}  component={'div'}>
            {props.milkingParams.milkYield.label}
            <Box fontWeight='fontWeightLight' sx={{ fontSize: 12 }} display='inline'>
              {props.milkingParams.milkYield.unit !== "" ? " [" + props.milkingParams.milkYield.unit + "]" : ""}
            </Box>
            {"hint" in props.milkingParams.milkYield ? <HelpOutline sx={{ fontSize: 16, marginLeft: 1}} color="primary" /> : <div/>}
          </Typography>
        </CustomWidthTooltip>
      </Grid>
      <Grid item xs={5} sm={3}>
        <Box component="form" sx={{display: 'flex'}}>
          <TextField
              variant="standard"
              name="compareVal"
              type="number"
              value={props.milkingParams.milkYield.value[0]}
              onChange={handleChange}
              error={isInvalidValue(props.milkingParams.milkYield.value[0])}
              helperText={isInvalidValue(props.milkingParams.milkYield.value[0]) ? invalidMessage:""}
              disabled
            />
          <IconButton color="primary" onClick={() => handleOpen(0)}>
            <Description />
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={5} sm={3} sx={{display: 'flex'}}>
        <TextField
          variant="standard"
          name="compareVal"
          type="number"
          value={props.milkingParams.milkYield.value[1]}
          onChange={handleChange}
          error={isInvalidValue(props.milkingParams.milkYield.value[1])}
          helperText={isInvalidValue(props.milkingParams.milkYield.value[1]) ? invalidMessage:""}
          disabled
        />
        <IconButton color="primary" onClick={() => handleOpen(1)}>
          <Description />
        </IconButton>
      </Grid>
      <Dialog onClose={handleClose} open={open}>
        <MilkingCurveCalc
          params={milkingParams(milkConfIdx)}
          presets={props.milkingParams.milkYield.preset}
          presetIdx={props.milkingParams.milkYield.preset_default_idx}
          setParams={setMilkingParams}
        />
      </Dialog>
    </Grid>
  )  
}

const ComparisonInput = (props) => {
  const classes = useStyles();

  const handleChange = (event) => {
    const newInput = JSON.parse(JSON.stringify(props.input))
    const setVal = event.target.value !== "" ? Number(event.target.value) : ""
    if (event.target.name === "currntVal") newInput.value[0] = setVal
    else if (event.target.name === "compareVal") newInput.value[1] = setVal
    else return
    props.setInput(newInput)
  };

  const isInvalidValue = (valstr) => {
    const valnumber = Number(valstr)
    if (isNaN(valnumber)) return true
    return valnumber < props.input.min || valnumber > props.input.max
  }

  const invalidMessage = String(props.input.min) + "～" + String(props.input.max) + "の数値を入力してください。"

  return (
    <Grid container alignItems="center" justifyContent="center"
          columnSpacing={{ xs: 2, sm: 2 }}>
      <Grid item xs={12} sm={6}>
        <CustomWidthTooltip
          title={"hint" in props.input ? props.input.hint : ""}
          placement="top" arrow enterTouchDelay={50} leaveTouchDelay={3000}
        >
          <Typography sx={{ mt: 2, mb: 1 , ml: 5}} className={classes.bold}  component={'div'}>
            {props.input.label}
            <Box fontWeight='fontWeightLight' sx={{ fontSize: 12 }} display='inline'>
              {props.input.unit !== "" ? " [" + props.input.unit + "]" : ""}
            </Box>
            {"hint" in props.input ? <HelpOutline sx={{ fontSize: 16, marginLeft: 1}} color="primary" /> : <div/>}
          </Typography>
        </CustomWidthTooltip>
      </Grid>
      <Grid item xs={5} sm={3}>
        <TextField
          variant="standard"
          name="currntVal"
          type="number"
          value={props.input.value[0]}
          onChange={handleChange}
          error={isInvalidValue(props.input.value[0])}
          helperText={isInvalidValue(props.input.value[0]) ? invalidMessage:""}
        />
      </Grid>
      <Grid item xs={5} sm={3}>
        <TextField
          variant="standard"
          name="compareVal"
          type="number"
          value={props.input.value[1]}
          onChange={handleChange}
          error={isInvalidValue(props.input.value[1])}
          helperText={isInvalidValue(props.input.value[1]) ? invalidMessage:""}
        />
      </Grid>
    </Grid>
  )
}

export const extractTag = (inputs) => {
  return Array.from(new Set(inputs.map((v) => v["tag"])))
}

const OptionalInput = (props) => {
  const [tagIndex, settagIndex] = React.useState(0);

  const handleChange = (event, newtagIndex) => {
    settagIndex(newtagIndex);
  };

  const setMilkingParams = (index, milkParams) => {
    const updateVal = props.inputs.slice();
    updateVal[index] = milkParams.milkYield;
    updateVal[index+1] = milkParams.scale;
    updateVal[index+2] = milkParams.ramp;
    updateVal[index+3] = milkParams.decay;
    props.setInputs(updateVal);
  }

  const setInput = (index, val) => {
    const updateVal = props.inputs.slice();
    updateVal[index] = val;
    props.setInputs(updateVal);
  }

  const isAllDataIsValid = () => {
    const isAllDataIsInBetweenMinMax = props.inputs.every(
      (val) => {
        return (
          val.min <= val.value[0] && val.value[0] <= val.max && val.min <= val.value[1] && val.value[1] <= val.max
        )
      }
    )
    return isAllDataIsInBetweenMinMax
  }

  const InputForms = () => {
    return (
      props.inputs.map(
        (input, index) => {
          if(input.tag !== extractTag(props.inputs)[tagIndex]) return null;
          if(input.id.includes("305_day_milk_yield")) {
            // parameter.jsonに泌乳曲線関係のパラメーターが連続で並んでいることを前提としており良くない実装。
            // 泌乳曲線の入力対応で詳細入力画面の実装が過剰に複雑になってしまっている...
            return (
              <MikingInput
                milkingParams={{
                  milkYield: props.inputs[index],
                  scale: props.inputs[index+1],
                  ramp: props.inputs[index+2],
                  decay: props.inputs[index+3],
                }}
                setMilkingParams={(milkParams) => setMilkingParams(index, milkParams)}
                key={index}
              />
            );
          }
          if(input.id.includes("scale_parity") || input.id.includes("ramp_parity") || input.id.includes("decay_parity")) return null;
          return (
            <ComparisonInput input={input} setInput={(v) => setInput(index, v)} key={index}/>
          );
        }
      )
    )
  }

  return (
    <Box>
      <Grid container>
        <Grid item xs={3}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tagIndex}
            onChange={handleChange}
            sx={{ borderRight: 1, borderColor: 'divider' }}
            >
            {extractTag(props.inputs).map((tag, index) => (
              <Tab label={tag} {...a11yProps(index)} key={tag}/>
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={9}>
          {InputForms()}
        </Grid>
      </Grid>
      <Box sx={{ m: 5 }} />
      <StepMoveButton
        moveToPrevStep={props.moveToPrevStep}
        moveToShowResultStep={props.moveToShowResultStep}
        disableButton={!isAllDataIsValid()}
      />
    </Box>
  )
}

export default OptionalInput