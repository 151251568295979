import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import StepperBar from './StepperBar';
import FarmTypeInput from './StepFarmType';
import RequiredInput from './StepRequiredInput';
import OptionalInput from './StepOptionalInput';
import Result from './StepResult';
import parameters from './parameters.json';
import { postCalcParams } from "../../components/api/s3"
import { useAuth0 } from "@auth0/auth0-react";


const StepPage = (props) => {
  const [farmType, setFarmType] = useState("");
  const [requiredInputs, setRequiredInputs] = useState(parameters.requiredInputs);
  const [optionalInputs, setOptionalInputs] = useState(parameters.optionalInputs);
  const { isAuthenticated, user } = useAuth0();

  const fileterInputs = (inputs) => {
    const isContain = (str, val) => str.indexOf(val) !== -1
    if(farmType === "dairy") return inputs.filter((v) => isContain(v.farmType, "酪農"))
    else if(farmType === "breeding") return inputs.filter((v) => isContain(v.farmType, "繁殖"))
  }

  const uploadCalcParams = () => {
    const userid = isAuthenticated? user.sub : localStorage.getItem("tempid")
    postCalcParams(userid, {farmType, requiredInputs, optionalInputs})
  }

  if (props.step === 0) {
    return (
      <FarmTypeInput
        farmType={farmType} setFarmType={setFarmType}
        moveToNextStep={() => props.moveStep(1)}
      />
    )
  }
  else if(props.step === 1) {
    return (
      <RequiredInput
        inputs={fileterInputs(requiredInputs)}
        setInputs={setRequiredInputs}
        moveToPrevStep={() => props.moveStep(0)}
        moveToInputOptionStep={() => props.moveStep(2)}
        moveToShowResultStep={() => props.moveStep(3)}
      />
    )
  }
  else if(props.step === 2) {
    return (
      <OptionalInput
        inputs={fileterInputs(optionalInputs)}
        setInputs={setOptionalInputs}
        moveToPrevStep={() => props.moveStep(1)}
        moveToShowResultStep={() => props.moveStep(3)}
      />
    )
  }
  else if(props.step === 3) {
    uploadCalcParams();
    return (
      <Result
        farmType={farmType}
        inputs={fileterInputs(requiredInputs.concat(optionalInputs))}
      />
    )
  }
  else return <div />
}

const CreeUI = () => {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const { isAuthenticated, isLoading } = useAuth0();
  const userid = localStorage.getItem("tempid");

  const moveStep = (targetStep) => {
    if(0 <= targetStep && targetStep <= 3) setStep(targetStep)
  }

  useEffect(() => {
    if(isLoading){
      return;
    }
    if(!isAuthenticated && userid===undefined){
      navigate("/")
    }
  }, [isAuthenticated, navigate, isLoading, userid]);

  return (
    <Grid container justifyContent="center">
      <Grid container item lg={8} md={10} xs={12}>
        <Grid container justifyContent="center" rowSpacing={2}>
          <Grid item>
            <StepperBar step={step} moveStepFunc={(s) => moveStep(s)} />
          </Grid>
          <Grid item md={9} xs={12}>
            <StepPage step={step} moveStep={moveStep} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CreeUI