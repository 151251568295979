import React, {useEffect} from 'react';
import { Box, Container, Typography, FormControl, FormLabel, FormGroup,
         FormControlLabel, Checkbox, Button, Select, Input, MenuItem } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { postSurvey, postUserInfo } from "../../components/api/s3"
import { useAuth0 } from "@auth0/auth0-react";


const SurveyForm = (props) => {
  const [checkboxStates, setCheckboxStates] = React.useState(props.contents.map(() => false));
  const handleChange = (event, index) => {
    checkboxStates[index] = event.target.checked
    setCheckboxStates(checkboxStates)
    props.setResult(props.contents.filter((_, idx) => checkboxStates[idx] === true ))
  }
  return (
    <FormControl required component="fieldset" variant="standard">
      <FormLabel component="legend">{props.title}</FormLabel>
      <FormGroup>
        {props.contents.map((val, idx) => (
          <FormControlLabel
            control={<Checkbox onChange={(event) => handleChange(event, idx)} name={val} />}
            label={val} key={val+String(idx)}
          />
        ))}
      </FormGroup>
    </FormControl>    
  )
}

const SURVEY_CONTENTS = [
  {"title": "職務内容", "options": ["生産者", "獣医師", "授精師", "削蹄師", "飼料販売", "動薬販売", "畜産設備販売", "研究職", "その他"]},
  {"title": "ユープロフェットを知ったきっかけ", "options": ["知人から聞いて", "勉強会で聞いて", "以前に使用して", "その他"]},
]

const Survey = () => {
  const navigate = useNavigate();
  const { isAuthenticated, user, isLoading } = useAuth0();
  const [surveyResults, setSurveyResults] = React.useState({});
  const [age, setAge] = React.useState('');
  const [acname, setName] = React.useState('');

  const setOneSurveyResult = (key, val) => {
    setSurveyResults({
      ...surveyResults,
      [key]: val
    })
  }

  const handleAgeChange = (event) => {
    setAge(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  useEffect(() => {
    if(isLoading) return;
    if(!isAuthenticated) navigate("/");
  }, [isAuthenticated, navigate, isLoading]);

  const sendSurveyResult = () => {
    const res = JSON.parse(JSON.stringify(surveyResults));
    res["お名前"] = acname;
    res["年齢層"] = age;
    console.log(user.sub);
    console.log(res);
    postSurvey(user.sub, res);
    postUserInfo(user.sub);
    navigate("/consent");
  }

  const isAllSelected = () => {
    const titles = SURVEY_CONTENTS.map((v) => v.title)
    const isSelected = titles.map(
      (v) => (surveyResults[v] !== undefined) && (surveyResults[v].length > 0)
    )
    const isAllSelected = isSelected.includes(false)
    return isAllSelected;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ display: 'flex', flexDirection: 'column'}}>
        <Typography component="h4" variant="h4">
          アカウント情報登録
        </Typography>
        <Box sx={{ mt: 2 }}>
          <FormControl>
            <FormLabel component="legend">お名前</FormLabel>
            <Input value={acname} onChange={handleNameChange} />
          </FormControl>
        </Box>
        <Box sx={{ mt: 2 }}>
          <FormControl variant="standard">
            <FormLabel component="legend">年齢層</FormLabel>
            <Select value={age} onChange={handleAgeChange}>
              <MenuItem value=""><em>なし</em></MenuItem>
              <MenuItem value={20}>~20代</MenuItem>
              <MenuItem value={30}>30代</MenuItem>
              <MenuItem value={40}>40代</MenuItem>
              <MenuItem value={50}>50代</MenuItem>
              <MenuItem value={60}>60代</MenuItem>
              <MenuItem value={70}>70代~</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {
          SURVEY_CONTENTS.map((c, _) => (
            <Box sx={{ mt: 2 }} key={c.title}>
              <SurveyForm
                title={c.title}
                contents={c.options}
                setResult={(val) => setOneSurveyResult(c.title, val)}
              />
            </Box>          
          ))
        }
        <Button
          fullWidth variant="contained" sx={{ mt: 3 }}
          disabled={isAllSelected()}
          onClick={sendSurveyResult}
        >
          次へ
        </Button>
      </Box>
    </Container>
  );
}

export default Survey
