import React from "react";
import { AppBar, Toolbar, Box, IconButton, Menu, MenuItem } from "@mui/material";
import logo from './logo.png';
import { useAuth0 } from "@auth0/auth0-react";
import AccountCircle from '@mui/icons-material/AccountCircle';


const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { isAuthenticated, logout } = useAuth0();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin})
    handleClose()
  };

  return (
    <AppBar position="static" color="default">
      <Toolbar>
        <div>
          <a href="/">
            <img style={{maxWidth: "72px"}} src={logo} alt="デザミス株式会社"></img>
          </a>
        </div>
        <Box sx={{ flexGrow: 1 }} />
        {isAuthenticated && (
          <div>
            <IconButton onClick={handleMenu} color="inherit">
              <AccountCircle />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;