import React from "react";

const Footer = () => {
  const footerStyle = {
    width: "100%",
    position: "sticky",
    top: "100vh",
    textAlign: "right",
    paddingBottom: "10px"
  };
  const linkStyle = {
    textDecoration: "none",
    color: "grey",
    margin: "10px"
  };
  return (
    <div style={footerStyle}>
      <a style={linkStyle} href="https://www.desamis.co.jp">運営会社</a>
      <a style={linkStyle} href="/policy">プライバシーポリシー</a>
    </div>
  );
};

export default Footer;