import React from 'react';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';


const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 150,
  },
});

 const StepMoveButton = (props) => {
  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={1}>
        <Button variant="outlined" color="primary" xs={1}
          onClick={() => props.moveToPrevStep()}>
          戻る
        </Button>
      </Grid>
      <Grid item xs={1} sm={2}/>
      <Grid item xs={4} sm={3} textAlign="right">
        <Button variant="outlined" color="primary" xs={1}
          onClick={() => props.moveToInputOptionStep()}
          disabled={props.disableButton}>
          詳細入力
        </Button>
      </Grid>
      <Grid item xs={4} sm={3} textAlign="right">
        <Button variant="contained" color="primary" xs={1}
          onClick={() => props.moveToShowResultStep()}
          disabled={props.disableButton}>
          結果表示
        </Button>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles({
  bold: {
    fontWeight: 520,
    color: "#606060"
  }
})

const ComparisonLabel = () => {
  const classes = useStyles();
  return (
    <Grid container columnSpacing={{ xs: 2, sm: 5, md: 5 }}>
      <Grid item xs={6} sm={5}>
      </Grid>
      <Grid item xs={3}>
        <Typography sx={{ mt: 2, mb: 1 }} className={classes.bold}>現状</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography sx={{ mt: 2, mb: 1 }} className={classes.bold}>比較</Typography>
      </Grid>
    </Grid>
  )
}

const ComparisonInput = (props) => {
  const classes = useStyles();

  const handleChange = (event) => {
    const newInput = JSON.parse(JSON.stringify(props.input))
    const setVal = event.target.value !== "" ? Number(event.target.value) : ""
    if (event.target.name === "currntVal") newInput.value[0] = setVal
    else if (event.target.name === "compareVal") newInput.value[1] = setVal
    else return
    props.setInput(newInput)
  };

  const isInvalidValue = (valstr) => {
    const valnumber = Number(valstr)
    if (isNaN(valnumber)) return true
    return valnumber < props.input.min || valnumber > props.input.max
  }

  const invalidMessage = String(props.input.min) + "～" + String(props.input.max) + "の数値を入力してください。"

  return (
    <Grid container alignItems="center" columnSpacing={{ xs: 2, sm: 5, md: 5 }}>
      <Grid item xs={6} sm={5}>
        <CustomWidthTooltip
          title={"hint" in props.input ? props.input.hint : ""}
          placement="top" arrow enterTouchDelay={50} leaveTouchDelay={3000}
        >
          <Typography sx={{ mt: 2, mb: 1 , ml: 5}} className={classes.bold}  component={'div'}>
            {props.input.label}
            <Box fontWeight='fontWeightLight' sx={{ fontSize: 12 }} display='inline'>{" [" + props.input.unit + "]"}</Box>
            {"hint" in props.input ? <HelpOutline sx={{ fontSize: 16, marginLeft: 1}} color="primary" /> : <div/>}
          </Typography>
        </CustomWidthTooltip>
      </Grid>
      <Grid item xs={3}>
        <TextField
          variant="standard"
          name="currntVal"
          type="number"
          value={props.input.value[0]}
          onChange={handleChange}
          error={isInvalidValue(props.input.value[0])}
          helperText={isInvalidValue(props.input.value[0]) ? invalidMessage:""}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          variant="standard"
          name="compareVal"
          type="number"
          value={props.input.value[1]}
          onChange={handleChange}
          error={isInvalidValue(props.input.value[1])}
          helperText={isInvalidValue(props.input.value[1]) ? invalidMessage:""}
        />
      </Grid>
    </Grid>
  )
}


const RequiredInput = (props) => {
  const setInput = (index, val) => {
    const updateVal = props.inputs.slice();
    updateVal[index] = val;
    props.setInputs(updateVal);
  }

  const isAllDataIsValid = () => {
    const isAllDataIsInBetweenMinMax = props.inputs.every(
      (val) => {
        return (
          val.min <= val.value[0] && val.value[0] <= val.max && val.min <= val.value[1] && val.value[1] <= val.max
        )
      }
    )
    return isAllDataIsInBetweenMinMax
  }

  return (
    <Box>
      <ComparisonLabel />
      {props.inputs.map((input, index) => (
        <ComparisonInput input={input} setInput={(v) => setInput(index, v)} key={index} />
      )
      )}
      <Box sx={{ m: 2 }} />
      <StepMoveButton
        moveToPrevStep={props.moveToPrevStep}
        moveToInputOptionStep={props.moveToInputOptionStep}
        moveToShowResultStep={props.moveToShowResultStep}
        disableButton={!isAllDataIsValid()}
      />
    </Box>
  )
}

export default RequiredInput