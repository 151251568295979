// eslint-disable-next-line
import Chart from 'chart.js/auto';
import { Typography } from '@mui/material';
import { Line } from 'react-chartjs-2';

const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            title: {
              display: true,
              text: "日数",
            },
          },
          y: {
            title: {
                display: true,
                text: "泌乳量 [kg/日]",
              },
            beginAtZero: true,
          },
      },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: '泌乳曲線',
      },
    },
  };


const convertToChartjsdata = (numArray) => {
    const decimateDays = 10
    const labels = [...Array(numArray.length).keys()].filter(i => i%decimateDays === 0);
    const datas = labels.map(i => numArray[i])
    return {
        labels,
        datasets: [
        {
            data: datas,
            borderColor: 'rgb(56, 184, 212)',
            backgroundColor: 'rgba(56, 184, 212, 0.2)',
            fill: true,
        }
        ],
    };
}

const MilkingCurvePlot = (props) => {
  const totalMilkMount = () => props.dataArray.slice(0, 305).reduce((sum, element) => sum + element, 0)
  return (
    <div style={{position: "relative", minHeight: 300}}>
      <Line options={options} data={convertToChartjsdata(props.dataArray)} />
      <Typography sx={{fontSize:{md: 20, xs:17}, position: "absolute", top: "50%", left: "30%"}}>
        305日実乳量:{parseInt(totalMilkMount())}kg
      </Typography>
    </div>
  );
}

export default MilkingCurvePlot