import React, {useEffect} from 'react';
import { CircularProgress, Container, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getUserInfo } from "../../components/api/s3"


const TempRedirect = () => {
  const userid = localStorage.getItem("tempid");
  const navigate = useNavigate();

  useEffect(() => {
    if(userid === undefined) navigate("/survey");
    else{
      console.log(userid)
      getUserInfo(userid).then(
        (res) => {
          console.log(res);
          if(res.data.isSurveyExist){
            navigate("/calculator");
          }
          else{
            navigate("/survey");
          }
      })
      .catch(err => {
        console.log(err)
        navigate("/survey");
      });
    }
  }, [navigate, userid]);

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ fontSize: 24, color:'#666666', paddingRight: 2 }} display='inline'>情報読み込み中...</Box>
      <CircularProgress />
    </Container>
  )
}

export default TempRedirect