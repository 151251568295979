import React from 'react';
import { Box, Container, Typography, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import shortid from "shortid";
import { postSurvey, postUserInfo } from "../../components/api/s3"


const SurveyForm = (props) => {
  const [checkboxStates, setCheckboxStates] = React.useState(props.contents.map(() => false));
  const handleChange = (event, index) => {
    checkboxStates[index] = event.target.checked
    setCheckboxStates(checkboxStates)
    props.setResult(props.contents.filter((_, idx) => checkboxStates[idx] === true ))
  }
  return (
    <FormControl required component="fieldset" variant="standard">
      <FormLabel component="legend">{props.title}</FormLabel>
      <FormGroup>
        {props.contents.map((val, idx) => (
          <FormControlLabel
            control={<Checkbox onChange={(event) => handleChange(event, idx)} name={val} />}
            label={val} key={val+String(idx)}
          />
        ))}
      </FormGroup>
    </FormControl>    
  )
}

const SURVEY_CONTENTS = [
  {"title": "職務内容", "options": ["生産者", "獣医師", "授精師", "削蹄師", "飼料販売", "動薬販売", "畜産設備販売", "研究職", "その他"]},
  {"title": "ユープロフェットを知ったきっかけ", "options": ["知人から聞いて", "勉強会で聞いて", "以前に使用して", "プレスリリースで見て", "その他"]},
]

const Survey = () => {
  const navigate = useNavigate();
  const [surveyResults, setSurveyResults] = React.useState({});
  const setOneSurveyResult = (key, val) => {
    setSurveyResults({
      ...surveyResults,
      [key]: val
    })
  }

  const sendSurveyResult = () => {
    const tempUserid = "temp-" + shortid.generate();
    console.log(tempUserid);
    console.log(surveyResults);
    postSurvey(tempUserid, surveyResults);
    postUserInfo(tempUserid);
    localStorage.setItem('tempid', tempUserid);
    navigate("/consent");
  }

  const isAllSelected = () => {
    const titles = SURVEY_CONTENTS.map((v) => v.title)
    const isSelected = titles.map(
      (v) => (surveyResults[v] !== undefined) && (surveyResults[v].length > 0)
    )
    const isAllSelected = isSelected.includes(false)
    return isAllSelected;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ display: 'flex', flexDirection: 'column'}}>
        <Typography component="h4" variant="h4">
          アンケート
        </Typography>
        {
          SURVEY_CONTENTS.map((c, _) => (
            <Box sx={{ mt: 2 }} key={c.title}>
              <SurveyForm
                title={c.title}
                contents={c.options}
                setResult={(val) => setOneSurveyResult(c.title, val)}
              />
            </Box>          
          ))
        }
        <Button
          fullWidth variant="contained" sx={{ mt: 3 }}
          disabled={isAllSelected()}
          onClick={sendSurveyResult}
        >
          次へ
        </Button>
      </Box>
    </Container>
  );
}

export default Survey
