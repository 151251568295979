import React from "react";
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './components/atoms/Header';
import Footer from './components/atoms/Footer';
import CreeUI from './pages/cree/Cree';
import Select from './pages/entry/Select';
import Survey from "./pages/entry/Survey";
import SignupSurvey from "./pages/entry/SignupSurvey";
import Consent from "./pages/consent/Consent";
import Policy from "./pages/consent/Policy";
import Redirect from "./pages/entry/Redirect";
import TempRedirect from "./pages/entry/TempRedirect";
import ParamSelect from "./pages/entry/ParamSelect";
import { initializeGA, useTracking } from './components/atoms/GoogleAnalyticsUtil';

const theme = createTheme({
  palette: {
    primary: { main: '#38B8D4', contrastText: "#fff"},
    secondary: { main: '#C8E8F4' },
  },
});

const Router = () => {
  const G_TRACK_ID = "G-GDCTE4ZKXH"
  useEffect(() => {
    // GoogleAnalytics gtag.js読み込み
    initializeGA(G_TRACK_ID);
  }, []);
  useTracking(G_TRACK_ID);

  return (
    <Routes>
      <Route exact path="/" element={<Select/>} />
      <Route exact path="/survey" element={<Survey/>} />
      <Route exact path="/signup-survey" element={<SignupSurvey/>} />
      <Route path="/calculator" element={<CreeUI/>} />
      <Route path="/consent" element={<Consent/>} />
      <Route path="/policy" element={<Policy/>} />
      <Route path="/redirect" element={<Redirect/>} />
      <Route path="/temp-redirect" element={<TempRedirect/>} />
      <Route path="/parameter-select" element={<ParamSelect/>} />
    </Routes>
  )
}


const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div style={{ marginTop: 30, marginBottom: 30, marginLeft: 10, marginRight: 10}}>
        <BrowserRouter>
          <Router/>
        </BrowserRouter>
      </div>
      <Footer />
    </ThemeProvider>
  );  
}

export default App;